import { getNonReservedQueryParamValues, type IRoomListAPIParams } from "@multimediallc/cb-roomlist-prefetch"
import { ArgJSONMap } from "@multimediallc/web-utils"
import { getCb } from "../../../common/api"
import { parseIRoomInfo } from "./roomCard"
import type { IRoomInfo } from "./IRoomInfo"

const FETCH_RETRY_LIMIT = 5

interface IRoomFetchResult {
    loadedRooms: IRoomInfo[]
    matchedCount: number
    totalCount: number
    fetchId: number  // Used for resolving potential race conditions
    roomListId?: string
}

interface IApiRoomsIteratorOptions {
    apiUrl: string
    pageSize?: number
}

export class PaginatedApiRoomsIterator {
    private filters: IRoomListAPIParams
    private apiUrl: string

    constructor(options: IApiRoomsIteratorOptions) {
        this.apiUrl = options.apiUrl
    }

    /**
     * Sets the filters that the rooms iterator should use for its API requests. Should include both dynamic
     * and category filters (if applicable) as the iterator doesn't distinguish between them.
     * @param newFilters An object containing the COMPLETE filter values to use, except for pagination.
     */
    public setFilters(newFilters: IRoomListAPIParams): void {
        this.filters = newFilters
    }

    /**
     * Fetches a full specific page of rooms from the API for the currently-applied filters
     * @param fetchId A unique number corresponding to this fetch request, to resolve race conditions
     * @param prefetchPromise Optional, used to handle promise results from roomlist-prefetch package
     * @param attempt Should not be passed explicitly, counts the number of times this specific fetch
     *     has failed and retried. Goes up to CHUNK_RETRY_LIMIT attempts before raising errors.
     * @returns A Promise for the page fetch request
     */
    public fetchPage(fetchId: number, prefetchPromise?: Promise<string>, attempt = 0): Promise<IRoomFetchResult> {
        const queryParams = new URLSearchParams({
            // Include existing URL params in the query string, particularly for the sake of ?force-<foo> split test params
            ...getNonReservedQueryParamValues(),
            ...this.filters as Record<string, string>,
        })
        queryParams.sort()  // Sort params to ensure request URLs with identical filters correspond 1:1
        // If a prefetch promise was passed in, attempt to get the results from that instead of making a new request before retrying
        const pagePromise = prefetchPromise ?? getCb(`${this.apiUrl}?${queryParams.toString()}`).then((xhr) => xhr.responseText)
        // Parse and return the response, or iterate up to FETCH_RETRY_LIMIT if unsuccessful
        return pagePromise.then((response) => this.parseResponse(response, fetchId)).catch((err) => {
            warn("SPA rooms fetch failed", { attempt: attempt, reason: err })
            if (attempt < FETCH_RETRY_LIMIT) {
                // If we had a prefetchPromise that failed, don't bother passing it through, it's no longer useful
                return this.fetchPage(fetchId,undefined, attempt + 1)
            } else {
                throw err
            }
        })
    }

    private parseResponse(response: string, fetchId: number): IRoomFetchResult {
        const jsonMap = new ArgJSONMap(response)
        return {
            loadedRooms: jsonMap.getList("rooms")?.map((room) => {
                return parseIRoomInfo(room)
            }) ?? [],
            matchedCount: jsonMap.getNumber("total_count"),
            totalCount: jsonMap.getNumberOrUndefined("all_rooms_count") ?? 0,
            roomListId: jsonMap.getStringOrUndefined("room_list_id"),
            fetchId,
        }
    }
}
