import { UrlState } from "@multimediallc/cb-roomlist-prefetch"
import { HTMLComponent } from "../../../../../common/defui/htmlComponent"
import { addPageAction } from "../../../../../common/newrelic"
import { dom } from "../../../../../common/tsxrender/dom"
import { buildQueryString, parseQueryString } from "../../../../../common/urlUtil"
import { FilterOption } from "../filterOption"
import type { FilterOptionProps, SectionOptionsList } from "../constants"


export interface FilterSectionProps {onFilterOptionClick: () => void}

export abstract class FilterSectionBase extends HTMLComponent<HTMLDivElement, FilterSectionProps> {
    protected optionsList: FilterOption[]
    protected props: FilterSectionProps
    protected filterKey: string
    protected sectionOptions: SectionOptionsList
    protected sectionTitle: string
    protected isSingleSelect: boolean

    protected createElement(props: FilterSectionProps): HTMLDivElement {
        this.props = props
        this.optionsList = []
        const optionProps = this.createOptionsProps()
        return <div className="filterSection" data-testid={`filter-${this.sectionTitle.toLowerCase().replace(/[ \/]/g, "-")}-section`}>
            <div className="filterSectionHeader" data-testid={`filter-${this.sectionTitle.toLowerCase().replace(/[ \/]/g, "-")}-header`}>{this.sectionTitle}</div>
            <div className="filterSectionOptions">
                {optionProps.map((optionProps: FilterOptionProps) => 
                    <FilterOption
                        testid={`filter-${this.sectionTitle.toLowerCase().replace(/[ \/]/g, "-")}-item`}
                        name={optionProps.name}
                        labelText={optionProps.labelText}
                        getHref={optionProps.getHref}
                        queryParamValue={optionProps.queryParamValue}
                        classRef={(c) => this.optionsList.push(c)}
                        optionIsActive={optionProps.optionIsActive}
                        handleLeftClick={optionProps.handleLeftClick}
                    />,
                )}
            </div>
        </div>
    }

    public updateSectionFromUrl(): void {
        this.optionsList?.map((option: FilterOption) => {
            option.updateState()
        })
    }

    protected createOptionsProps(): FilterOptionProps[] {
        return this.sectionOptions.map((options) => {
            return {
                ...options,
                getHref: () => getToggledUrl(this.filterKey, options.queryParamValue, this.isSingleSelect).href,
                optionIsActive: () => this.optionIsActive(options.queryParamValue),
                handleLeftClick: () => this.handleLeftClick(options.queryParamValue),
            }
        })
    }

    protected handleLeftClick(queryParamValue: string): void {
        const newUrl = getToggledUrl(this.filterKey, queryParamValue, this.isSingleSelect)
        UrlState.current.pushUrl(newUrl)
        this.props.onFilterOptionClick()
        addPageAction("HmpgFilterOptionClicked", {
            "category": this.filterKey,
            "value": queryParamValue,
            "active": this.optionIsActive(queryParamValue),
        })
    }

    protected abstract optionIsActive(queryParamValue: string): boolean
}


export function getToggledUrl(key: string, value: string, singleSelect = false): URL {
    const params = getToggledUrlParams(key, value, singleSelect)
    const queryString = buildQueryString(params)
    const newUrl = new URL(window.location.href)
    newUrl.search = queryString
    return newUrl
}


function getToggledUrlParams(key: string, value: string, singleSelect = false): Record<string, string | undefined> {
    const activeFilters = parseQueryString(window.location.search)
    const activeParamVals = activeFilters[key]
    let params = activeParamVals !== undefined ? activeParamVals.split(",") : []
    if (params.includes(value)) {
        params.splice(params.indexOf(value), 1)
    } else {
        if (singleSelect) {
            params = [value]
        } else {
            params.push(value)
            params.sort()
        }
    }

    if (params.length > 0) {
        activeFilters[key] = params.join(",")
    } else {
        delete activeFilters[key]
    }
    return activeFilters
}
