import {
    FOLLOW_RECOMMENDATIONS_API_URL,
    FOLLOW_RECOMMENDATIONS_PAGE_SIZE,
    getAPIParamsFromURLState,
    HOMEPAGE_KEYS_NO_PAGE,
    isFollowedCams,
    ShowType,
    UrlState,
} from "@multimediallc/cb-roomlist-prefetch"
import { HTMLComponent } from "../../../common/defui/htmlComponent"
import { isRecommendedFollowRoomsActive } from "../../../common/featureFlagUtil"
import { i18n } from "../../../common/translation"
import { dom } from "../../../common/tsxrender/dom"
import { PaginatedApiRoomList } from "./paginatedApiRoomList"
import type { AdvancedSearchOptions } from "../../advancedSearchOptions"

export interface IFollowRecommendationsProps {
    animate: boolean
    showLocation: boolean
    advancedSearchOptions?: AdvancedSearchOptions
}

// Roomlist container subclass specifically intended for RcmFlwRm.
export class FollowRecommendationsRoomlistContainer extends HTMLComponent<HTMLDivElement, IFollowRecommendationsProps> {
    private roomlist: PaginatedApiRoomList

    protected initUI(): void {
        this.hideElement()
    }

    protected initData(): void {
        UrlState.current.listen([...HOMEPAGE_KEYS_NO_PAGE, "pageType"], () => {
            this.updateOrHideElement()
        }, this.element)
        this.updateOrHideElement()
    }

    private isActive(): boolean {
        return isFollowedCams() && isRecommendedFollowRoomsActive()
    }

    private updateOrHideElement(): void {
        // The recommendations aren't disposed when navigating
        // to non follow pages, thus we update only on the follow page and hide otherwise
        if (UrlState.current.state.showType === ShowType.FOLLOW) {
            this.updateRooms()
        } else {
            this.hideElement()
        }
    }

    private possiblyShowElement(): void {
        if (this.isActive() && this.roomlist.rooms.length > 0) {
            this.showElement()
        } else {
            this.hideElement()
        }
    }

    protected createElement(props: IFollowRecommendationsProps): HTMLDivElement {
        return <div className="followRecommendations roomlist_container">
            <h2>{i18n.recommendedForYou}</h2>
            <PaginatedApiRoomList
                classRef ={(c) => {this.roomlist = c}}
                apiUrl={FOLLOW_RECOMMENDATIONS_API_URL}
                pageSize={FOLLOW_RECOMMENDATIONS_PAGE_SIZE}
                animate={props.animate}
                showLocation={props.showLocation}
            />
        </div>
    }

    private updateRooms(): void {
        const params = getAPIParamsFromURLState(UrlState.current.state)
        this.roomlist.fetchRooms(
            undefined,
            params,
        ).then(() => {
            this.possiblyShowElement()
        }).catch((err) => {
            error("Failed to load roomlist page after retrying", err)
            this.hideElement()
        })
    }
}
