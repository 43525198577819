import { t } from "@lingui/macro"
import { ShowType, SpyShowPrices, UrlState } from "@multimediallc/cb-roomlist-prefetch"
import { HTMLComponent } from "../../../../../common/defui/htmlComponent"
import { ignoreMetaClick } from "../../../../../common/DOMutils"
import { featureFlagIsActive } from "../../../../../common/featureFlag"
import { isPrivateSpyBadgesActive } from "../../../../../common/featureFlagUtil"
import { addPageAction } from "../../../../../common/newrelic"
import { i18n } from "../../../../../common/translation"
import { dom } from "../../../../../common/tsxrender/dom"


export class SpyShowPriceSection extends HTMLComponent<HTMLDivElement> {
    protected createElement(props: object): HTMLDivElement {
        return <div className="filterSection" data-testid="filter-spy-show-tokens-per-minute-section"
            bind={{ display: () => UrlState.current.state.showType === ShowType.PRIVATE ? "block": "none" }}>
            <div className="filterSectionHeader"
                data-testid="filter-spy-show-tokens-per-minute-header">{t`SPY ON CAM TOKENS PER MINUTE`}</div>
            <div className="filterSectionOptions">
                {[...getSpyShowPriceMapping().keys()].map(k => 
                    <a
                        bind={{
                            className: () => `filterOption ${UrlState.current.state.spyShowPrices?.includes(k) === true ? "active" : ""}`,
                            href: () => UrlState.current.getURLForPartialState({ spyShowPrices: [k] }),
                        }}
                        onClick={(e: MouseEvent) => ignoreMetaClick(e, () => {
                            const initiallyActive = UrlState.current.state.spyShowPrices?.includes(k) ?? false
                            if (isPrivateSpyBadgesActive()) {
                                const prices = UrlState.current.state.spyShowPrices ?? []
                                const newPrices = initiallyActive ? prices?.filter((r) => r !== k) : [...prices, k]
                                newPrices?.sort()
                                UrlState.current.setPartialState({ spyShowPrices: newPrices })
                            } else {
                                if (initiallyActive) {
                                    UrlState.current.clearStateKeys(["spyShowPrices"])
                                } else {
                                    UrlState.current.setPartialState({ spyShowPrices: [k] })
                                }
                            }
                            addPageAction("HmpgFilterOptionClicked", {
                                "category": "spy_prices",
                                "value": k,
                                "active": !initiallyActive,
                            })
                        })}
                        data-testid="filter-spy-show-tokens-per-minute-item"
                    >{getSpyShowPriceMapping().get(k)}</a>,
                )}
            </div>
        </div>
    }
}


export function getSpyShowPriceMapping(): Map<SpyShowPrices, string> {
    return new Map<SpyShowPrices, string>([
        [SpyShowPrices.Value6, featureFlagIsActive("LinguiBasic") ? t`6 tks` : i18n.hmpgFilterOpt6TksAbbrev],
        [SpyShowPrices.Range12to18, featureFlagIsActive("LinguiBasic") ? t`12 - 18 tks`: i18n.hmpgFilterOpt12To18TksAbbrev],
        [SpyShowPrices.Range30to42, featureFlagIsActive("LinguiBasic") ? t`30 - 42 tks`: i18n.hmpgFilterOpt30TksAbbrev],
        [SpyShowPrices.Range54to66, t`54 - 66 tks`],
        [SpyShowPrices.Range90Plus, t`90+ tks`],
    ])
}
