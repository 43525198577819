import { t } from "@lingui/macro"
import { ROOM_SIZE_FILTER_KEY, UrlState } from "@multimediallc/cb-roomlist-prefetch"
import { HTMLComponent } from "../../../../../common/defui/htmlComponent"
import { ignoreMetaClick } from "../../../../../common/DOMutils"
import { isLinguiBasicActive } from "../../../../../common/featureFlagUtil"
import { addPageAction } from "../../../../../common/newrelic"
import { i18n } from "../../../../../common/translation"
import { dom } from "../../../../../common/tsxrender/dom"
import { ROOM_SIZE_MAPPING, ROOM_SIZE_OPTIONS } from "../constants"
import { FilterSectionBase } from "./filterSectionBase"
import type { FilterSectionProps } from "./filterSectionBase"
import type { RoomSize } from "@multimediallc/cb-roomlist-prefetch"

export class RoomSizeSection extends HTMLComponent<HTMLDivElement> {
    protected createElement(): HTMLDivElement {
        const mappings = ROOM_SIZE_MAPPING
        return <div className="filterSection" data-testid="filter-room-size-section">
            <div className="filterSectionHeader" data-testid="filter-room-size-header">
                {isLinguiBasicActive() ? t`ROOM SIZE` : i18n.roomSizeCAPS}
            </div>
            <div className="filterSectionOptions">
                {[...mappings.keys()].map(k => 
                    <a
                        bind={{
                            className: () => `filterOption ${UrlState.current.state.roomSize === k ? "active" : ""}`,
                            href: () => UrlState.current.getURLForPartialState({ roomSize: getToggledRoomSize(k) }),
                        }}
                        onClick={(e: MouseEvent) => {
                            ignoreMetaClick(e, () => {
                                const newSize = getToggledRoomSize(k)
                                UrlState.current.setPartialState({ roomSize: newSize })
                                addPageAction("HmpgFilterOptionClicked", {
                                    "category": "room_size",
                                    "value": k,
                                    "active": newSize === k,
                                })
                            })
                        }
                        }
                        data-testid="filter-room-size-item"
                    >{mappings.get(k)}</a>,
                )}
            </div>
        </div>
    }
}

function getToggledRoomSize(size: RoomSize): RoomSize | undefined {
    return UrlState.current.state.roomSize === size ? undefined : size
}

export class RoomSizeFilterSection extends FilterSectionBase {

    protected createElement(props: FilterSectionProps): HTMLDivElement {
        this.filterKey = ROOM_SIZE_FILTER_KEY
        this.sectionOptions = ROOM_SIZE_OPTIONS
        this.sectionTitle = isLinguiBasicActive() ? t`ROOM SIZE` : i18n.roomSizeCAPS
        this.isSingleSelect = true
        return super.createElement(props)
    }

    protected optionIsActive(queryParamValue: string): boolean {
        const currentValue = UrlState.current.state["roomSize"]
        if (currentValue === undefined) {
            return false
        }
        return currentValue === queryParamValue
    }
}
