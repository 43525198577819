import {
    FILTER_PANEL_OPEN_CACHE_KEY,
    N_DAYS_HOMEPAGE_FILTERS_CACHE_EXP,
} from "@multimediallc/cb-roomlist-prefetch"
import { Gender, getCurrentGender } from "@multimediallc/gender-utils"
import { setLocalStorageWithExpiration } from "@multimediallc/web-utils/storage"

export function setFilterPanelOpenCached(isOpen: boolean): void {
    setLocalStorageWithExpiration(FILTER_PANEL_OPEN_CACHE_KEY, String(isOpen), N_DAYS_HOMEPAGE_FILTERS_CACHE_EXP)
}

export function getGenderForTagsApi(): Gender {
    const gender = getCurrentGender()
    return gender === Gender.Trans ? Gender.OldTrans : gender
}
