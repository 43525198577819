import { HTMLComponent } from "../../../../common/defui/htmlComponent"
import { ignoreMetaClick } from "../../../../common/DOMutils"
import { dom } from "../../../../common/tsxrender/dom"
import type { FilterOptionProps } from "./constants"


export class FilterOption extends HTMLComponent<HTMLAnchorElement, FilterOptionProps> {
    protected createElement(props: FilterOptionProps): HTMLAnchorElement {
        return <a
            bind={{
                className: () => `filterOption ${props.optionIsActive() ? "active" : ""}`,
                href: () => props.getHref(),
            }}
            onClick={(e: MouseEvent) => { ignoreMetaClick(e, () => props.handleLeftClick())  }}
            data-testid={props.testid}
        >
            {props.name}
        </a>
    }

    protected initUI(props: FilterOptionProps): void {
        super.initUI(props)
        this.updateState()  // trigger the initial "bind"
    }
}
