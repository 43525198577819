import {
    clearStickyFilters,
    DEFAULT_MAX_AGE,
    DEFAULT_MIN_AGE,
    FILTER_PANEL_KEYS,
    shouldShowHomepageFilters,
    ShowType,
    UrlState,
} from "@multimediallc/cb-roomlist-prefetch"
import { SPOKEN_LANGUAGES_MAP } from "@multimediallc/web-utils"
import { HTMLComponent } from "../../../../common/defui/htmlComponent"
import { isSpyPriceFiltersActive } from "../../../../common/featureFlagUtil"
import { addPageAction } from "../../../../common/newrelic"
import { dom } from "../../../../common/tsxrender/dom"
import { PRIVATE_PRICES_MAPPING, REGION_MAPPING, ROOM_SIZE_MAPPING } from "./constants"
import { FilterLabel } from "./filterLabel"
import { FiltersClearButton } from "./filtersClearButton"
import { getSpyShowPriceMapping } from "./filterSections/spyShowPriceSection"
import type { IURLState, PrivatePrices, Region , SpyShowPrices } from "@multimediallc/cb-roomlist-prefetch"


export interface FilterLabelSectionProps {
    onClearAll?: () => void
    onRemoval?: () => void
}

export class FilterLabelSection extends HTMLComponent<HTMLDivElement> {
    protected clearButton: FiltersClearButton
    private labels: FilterLabel[] = []

    protected createElement(): HTMLDivElement {
        return <div className="filterLabelSection" bind={{
            display: () => shouldShowHomepageFilters() ? "block" : "none",
            style: () => {return { marginTop: this.isFollowedPage() ? "10px" : "" }},
        }}>
            <FiltersClearButton
                classRef={(c) => { this.clearButton = c }}
                onClearAll={() => {
                    addPageAction("HmpgFiltersClearButtonClicked")
                    const isSpyPriceShown = UrlState.current.state.showType === ShowType.PRIVATE
                        && isSpyPriceFiltersActive()
                    const toClearKeys: (keyof IURLState)[] = [
                        ...isSpyPriceShown ? FILTER_PANEL_KEYS :
                            [...FILTER_PANEL_KEYS].filter((key) => key !== "spyShowPrices"),
                        "keywords", "page", "pageb"]
                    if (isSpyPriceShown) {
                        clearStickyFilters()
                    }
                    UrlState.current.clearStateKeys(toClearKeys)
                    this.updateState()
                }}
            />
        </div>
    }

    protected initData(props: FilterLabelSectionProps): void {
        super.initData(props)

        UrlState.current.listen([...FILTER_PANEL_KEYS],
            () => {
                this.updateState()
            }, this.element)
    }

    private refresh(state: IURLState): void {
        const labels = []
        if ((state.regions ?? []).length > 0) {
            REGION_MAPPING.forEach((region: string, key: Region) => {
                if ((state.regions ?? []).includes(key)) {
                    labels.push(new FilterLabel({
                        title: region,
                        onRemoval: () => this.removeFilter({ regions: state.regions?.filter((r: string) => r !== key) }),
                    }))
                }
            })
        }
        if (state.roomSize !== undefined) {
            labels.push(new FilterLabel({
                title: ROOM_SIZE_MAPPING.get(state.roomSize) ?? "",
                onRemoval: () => this.removeFilter({ roomSize: undefined }),
            }))
        }
        if ((state.tags ?? []).length > 0) {
            labels.push(new FilterLabel({
                title: `#${(state.tags ?? [])[0]}`,
                onRemoval: () => this.removeFilter({ tags: [] }),
            }))
        }
        if (state.ageMin !== undefined || state.ageMax !== undefined) {
            labels.push(new FilterLabel({
                title: this.getAgeLabelText(state),
                onRemoval: () => this.removeFilter({ ageMin: undefined, ageMax: undefined }),
            }))
        }
        labels.push(...this.getPriceLabels(state))
        state.spokenLanguages?.forEach((lang: string) => {
            labels.push(new FilterLabel({
                title: SPOKEN_LANGUAGES_MAP.get(lang) ?? "",
                onRemoval: () => this.removeFilter({ spokenLanguages: [] }),
            }))
        })

        this.labels.forEach((label) => label.element.remove())
        this.labels = labels
        this.labels.reverse().forEach((label) => this.prependChild(label))
        this.clearButton.showOrHideElement(this.labels.length > 0, "inline-block")
    }

    private getPriceLabels(state: IURLState): FilterLabel[] {
        const labels: FilterLabel[] = []
        // For SpyPrFlt, removes the private price label when on spy-on-cams
        if (!isSpyPriceFiltersActive() || UrlState.current.state.showType !== ShowType.PRIVATE) {
            state.privatePrices?.forEach((price: PrivatePrices) => {
                labels.push(new FilterLabel({
                    title: PRIVATE_PRICES_MAPPING.get(price) ?? "",
                    onRemoval: () => this.removeFilter({ privatePrices: state.privatePrices?.filter((r: string) => r !== price) }),
                }))
            })
        }
        if (isSpyPriceFiltersActive() && UrlState.current.state.showType === ShowType.PRIVATE) {
            state.spyShowPrices?.forEach((price: SpyShowPrices) => {
                labels.push(new FilterLabel({
                    title: getSpyShowPriceMapping().get(price) ?? "",
                    onRemoval: () => this.removeFilter({ spyShowPrices: state.spyShowPrices?.filter((r) => r !== price) }),
                }))
            })
        }
        return labels
    }

    updateState(): void {
        super.updateState()
        this.refresh(UrlState.current.state)
    }

    private removeFilter(param: Partial<IURLState>): void {
        UrlState.current.setPartialState({ ...param })
    }


    private getAgeLabelText(state: IURLState): string {
        const ageMin = state.ageMin ?? DEFAULT_MIN_AGE
        const ageMax = state.ageMax ?? DEFAULT_MAX_AGE
        if (ageMin === ageMax - 1) {
            return ageMin.toString()
        } else if (ageMax < DEFAULT_MAX_AGE) {
            return `${ageMin} - ${ageMax - 1}`
        } else {
            return `${ageMin} +`
        }
    }

    private isFollowedPage() {
        return UrlState.current.state.showType === ShowType.FOLLOW || UrlState.current.state.showType === ShowType.FOLLOW_OFFLINE
    }
}
