import {
    getFilterPanelOpenCached,
    HOMEPAGE_KEYS_NO_PAGE,
    isLanguageFilterActive,
    isRoomSizeFilteringActive,
    shouldShowHomepageFilters,
    SPOKEN_LANGUAGE_FILTER_KEY,
    UrlState,
} from "@multimediallc/cb-roomlist-prefetch"
import { HTMLComponent } from "../../../../common/defui/htmlComponent"
import { isSpyPriceFiltersActive } from "../../../../common/featureFlagUtil"
import { addPageAction } from "../../../../common/newrelic"
import { dom } from "../../../../common/tsxrender/dom"
import { ReactWrapper } from "../../ReactWrapper"
import { HomepageFilterButton } from "./filterButton"
import { AgeSection } from "./filterSections/ageSection"
import { getToggledUrl } from "./filterSections/filterSectionBase"
import { PrivatePriceSection } from "./filterSections/privatePriceSection"
import { RegionSection } from "./filterSections/regionSection"
import { RoomSizeSection } from "./filterSections/roomSizeSection"
import { SpyShowPriceSection } from "./filterSections/spyShowPriceSection"
import { TagSection } from "./filterSections/tagSection"


interface FilterPanelProps {
    handleClose?: () => void
    onFilterOptionClick?: () => void
}

interface FilterPanelState {isOpen: boolean}

export class HomepageFilterPanel extends HTMLComponent<HTMLDivElement, FilterPanelProps, FilterPanelState> {

    // eslint-disable-next-line complexity
    protected createElement(props: FilterPanelProps): HTMLDivElement {
        return <div className="homepageFilterPanel" bind={{ display: () => shouldShowHomepageFilters() && this.state.isOpen ? "inline-block" : "none" }}>
            <div onClick={() => this.handleCloseButtonClick()} className="closeButton">
                <ReactWrapper
                    component="CloseButton"
                    componentProps={{}}
                />
            </div>
            {isSpyPriceFiltersActive() && !new URLSearchParams(window.location.search).has("spyLast") && <SpyShowPriceSection/>}
            <RegionSection/>
            {isRoomSizeFilteringActive() && <RoomSizeSection/>}
            {isLanguageFilterActive() && <ReactWrapper
                component="LanguageFilterSection"
                componentProps={{
                    onFilterOptionClick: (selectedLanguage: string) => onLanguageFilterOptionClick(selectedLanguage),
                    urlState: UrlState.current,
                    getToggledHref: getToggledLanguageHref,
                }}
            />}
            <TagSection
                onFilterOptionClick={props.onFilterOptionClick ?? (() => {})}
            />
            <AgeSection/>
            <PrivatePriceSection/>
            {isSpyPriceFiltersActive() && new URLSearchParams(window.location.search).has("spyLast") && <SpyShowPriceSection/>}
        </div>
    }

    protected initData(props: FilterPanelProps): void {
        super.initData(props)
        this.setState({ isOpen: getFilterPanelOpenCached() })
        UrlState.current.listen(HOMEPAGE_KEYS_NO_PAGE, () => {
            this.updateState()
        }, this.element)
        HomepageFilterButton.filterPanelOpen.listen((isActive) => {
            this.setState({ ...this.state, isOpen: isActive })
        })
    }

    private handleCloseButtonClick(): void {
        HomepageFilterButton.filterPanelOpen.fire(false)
        addPageAction("HmpgFilterPanelMovement", { "open": this.state.isOpen })
    }
}

export function onLanguageFilterOptionClick(selectedLanguage: string): void {
    addPageAction("HmpgFilterOptionClicked", {
        "category": SPOKEN_LANGUAGE_FILTER_KEY,
        "value": selectedLanguage,
        "active": UrlState.current.state.spokenLanguages?.[0] !== selectedLanguage,
    })

    if (UrlState.current.state.spokenLanguages?.[0] === selectedLanguage) {
        UrlState.current.clearStateKeys(["spokenLanguages"])
    } else {
        UrlState.current.setPartialState({ spokenLanguages: [selectedLanguage] })
    }
}

function getToggledLanguageHref(language: string): string {
    return getToggledUrl(SPOKEN_LANGUAGE_FILTER_KEY, language, true).href
}
