import { t } from "@lingui/macro"
import { homepageFiltersCurrentlyApplied } from "@multimediallc/cb-roomlist-prefetch"
import { HTMLComponent } from "../../../../common/defui/htmlComponent"
import { dom } from "../../../../common/tsxrender/dom"

interface FiltersClearButtonProps {onClearAll: () => void}

export class FiltersClearButton extends HTMLComponent<HTMLDivElement, FiltersClearButtonProps> {

    protected createElement(props: FiltersClearButtonProps): HTMLDivElement {
        return <div
            onClick={() => { this.clearAll(props) }}
            className="filtersClearButton"
            data-testid="filter-clear-button"
        >
            <div colorClass="text">{t`Clear All`}</div>
        </div>
    }

    private clearAll(props: FiltersClearButtonProps): void {
        props.onClearAll()
    }

    protected initData(props: FiltersClearButtonProps): void {
        super.initData(props)
        this.showOrHideElement(homepageFiltersCurrentlyApplied(), "inline-block")
    }
}
