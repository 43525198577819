import {
    ALL,
    isPremium,
    isRoomRoomlistSpaActive,
    PageType,
    UrlState,
} from "@multimediallc/cb-roomlist-prefetch"
import { isiPhone, isiPod } from "@multimediallc/web-utils/modernizr"
import { addEventListenerMultiPoly } from "../../../common/addEventListenerPolyfill"
import { Component } from "../../../common/defui/component"
import { HTMLComponent } from "../../../common/defui/htmlComponent"
import { getScrollbarWidth } from "../../../common/DOMutils"
import {
    isEmptySearchResultsPageActive,
    isFallbackRoomlistActive,
    isRecommendedFollowRoomsActive,
} from "../../../common/featureFlagUtil"
import { dom } from "../../../common/tsxrender/dom"
import { AdvancedSearchOptions } from "../../advancedSearchOptions"
import { spaPageContext } from "../../interfaces/context"
import { RoomReload } from "../../roomList"
import { makeResponsive } from "../../ui/responsiveUtil"
import { GendersTabs } from "../header/gendersTabs"
import { MobileSiteNotice } from "../MobileSiteNotice"
import { ReactWrapper } from "../ReactWrapper"
import { FallbackRoomlistContainer } from "./fallbackRoomlistContainer"
import { HomepageFilterButton } from "./filters/filterButton"
import { FilterLabelSection } from "./filters/filterLabelSection"
import { HomepageFilterPanel } from "./filters/filterPanel"
import { FollowedOnlineOfflineTab } from "./followedOnlineOfflineTab"
import { FollowRecommendationsRoomlistContainer } from "./followRecommendationsRoomlistContainer"
import { HomepageRoomlistContainer } from "./homepageRoomlistContainer"
import {
    INACTIVE_THRESHOLD_MILLISECONDS,
    PREMIUM_REFRESH_SECONDS,
    SPA_RELOAD_MILLISECONDS,
} from "./spaConstants"
import { isRoomRoomlistSpaEligiblePage } from "./spaHelpers"
import { bindSpaJoinOverlay } from "./spaJoinOverlay"
import type { IRoomListDossier } from "../../../entrypoints/roomlist"
import type { ReactComponent } from "../ReactRegistry"
import type { IURLState } from "@multimediallc/cb-roomlist-prefetch"

const ROOMLIST_MAIN_CONTENT_ID_SELECTOR = "#roomlist_content_wrapper"

export class RoomlistRoot extends HTMLComponent<HTMLDivElement, IRoomListDossier> {
    private props: IRoomListDossier
    private filterPanel?: HomepageFilterPanel
    private roomlistContainer: HomepageRoomlistContainer
    private secondaryContainer: HomepageRoomlistContainer
    private fallbackContainer?: FallbackRoomlistContainer
    private lastUserActivityTimestamp = 0
    private searchResultsHeader?: ReactComponent
    protected sharedNoSearchResultsMessage?: ReactComponent

    protected createElement(props: IRoomListDossier): HTMLDivElement {
        this.props = props
        const advancedSearchOptions = new AdvancedSearchOptions(false, true)
        return <div id="roomlist_root" data-testid="room-list">
            {isEmptySearchResultsPageActive() && <div className="SearchResultsHeaderContainer">
                <ReactWrapper
                    component="SearchResultsHeader"
                    componentProps={{ keywords: UrlState.current.state.keywords }}
                    reactRef={(ref) => {this.searchResultsHeader = ref}}
                />
            </div>}
            <FollowedOnlineOfflineTab />
            <FilterLabelSection/>
            {isEmptySearchResultsPageActive() &&
                <ReactWrapper
                    component="NoSearchResultsMessage"
                    componentProps={{ usageType: "shared" }}
                    reactRef={(ref) => {this.sharedNoSearchResultsMessage = ref}}
                />
            }
            <HomepageRoomlistContainer advancedSearchOptions={advancedSearchOptions}
                classRef={(c) => { this.roomlistContainer = c }}
                animate={this.props.animateThumbnails}
                showLocation={this.props.showLocation}
                appName={this.props.appName}
                searchResultsHeader={this.searchResultsHeader}
            />
            <HomepageRoomlistContainer advancedSearchOptions={advancedSearchOptions}
                classRef={(c) => { this.secondaryContainer = c }}
                animate={this.props.animateThumbnails}
                showLocation={this.props.showLocation}
                isSecondary={true}
                searchResultsHeader={this.searchResultsHeader}
            />
            {isRecommendedFollowRoomsActive() && <FollowRecommendationsRoomlistContainer
                animate={this.props.animateThumbnails}
                showLocation={this.props.showLocation}
            />}
            {isFallbackRoomlistActive() && <FallbackRoomlistContainer
                classRef={(c) => this.fallbackContainer = c}
                animate={this.props.animateThumbnails}
                showLocation={this.props.showLocation}
            />}
        </div>
    }

    /**
     * INIT METHODS
     */

    protected initUI(props: IRoomListDossier): void {
        super.initUI(props)
        this.initTopSectionNav()
        this.makeRoomListResponsive()

        if (props.isTestbed) { return }  // Nothing else to init if no room cards to show

        this.bindRoomRefreshTimers()
        if (!isRoomRoomlistSpaActive()) {
            bindSpaJoinOverlay()
            const buttonContainer = document.querySelector<HTMLDivElement>(".advanced-search-button-container") as HTMLDivElement
            buttonContainer.appendChild(
                <HomepageFilterButton />,
            )
        }
        document.querySelector(".homepageFilterPanel")!.replaceWith(
            <HomepageFilterPanel classRef={c => this.filterPanel = c} />,
        )
        UrlState.current.listen(["pageType", "showType"], (state: IURLState) => {
            if (state.pageType === PageType.HOME || state.pageType === PageType.ROOM) {
                this.updateState()
            }
        }, this.element)
        if (isFallbackRoomlistActive()) {
            UrlState.current.listen(ALL, () => {
                // Initially hide fallback roomlist on all state changes, if it needs to be shown
                // it will be triggered by the completion of the primary roomlist fetch
                this.fallbackContainer?.hideElement()
            }, this.element)
        }

        if (isEmptySearchResultsPageActive()) {
            // Controls whether the sharedNoSearchResultsMessage is shown or hidden. Setting the component's matchedCount to
            // undefined will hide the component, while setting the variable to zero shows the component.
            // We determine the value to pass in using the member variables for matchedCount and totalCount in the
            // HomepageRoomlistContainer components. On roomlistLoaded, we check if these are set to numeric values, and if so,
            // sum the two respective count types for usage in the sharedNoSearchResultsMessage, as seen below.
            UrlState.current.listen(ALL, () => {
                this.sharedNoSearchResultsMessage?.update({ matchedCount: undefined, totalCount: undefined })
            }, this.element)

            HomepageRoomlistContainer.roomlistLoaded.listen(() => {
                if (this.roomlistContainer.matchedCount !== undefined && this.secondaryContainer.matchedCount !== undefined && this.roomlistContainer.totalCount !== undefined && this.secondaryContainer.totalCount !== undefined) {
                    const totalMatchedCount = this.roomlistContainer.matchedCount + this.secondaryContainer.matchedCount
                    const summedTotalCount = this.roomlistContainer.totalCount + this.secondaryContainer.totalCount
                    this.sharedNoSearchResultsMessage?.update({ matchedCount: totalMatchedCount, totalCount: summedTotalCount })

                    if (totalMatchedCount === 0) {
                        this.roomlistContainer.hideElement()
                        this.secondaryContainer.hideElement()
                    }
                }
            })
        }

        this.updateState()
    }

    updateState(): void {
        const mainDiv = new Component(document.querySelector<HTMLDivElement>(ROOMLIST_MAIN_CONTENT_ID_SELECTOR)!)
        const isHomepage = UrlState.current.state.pageType === PageType.HOME
        mainDiv.showOrHideElement(isHomepage)
        this.filterPanel?.updateState()
        super.updateState()
    }

    private initTopSectionNav(): void {
        if (isRoomRoomlistSpaActive()) {
            return
        }
        const topSection = new Component(document.querySelector<HTMLDivElement>(".top-section")!)
        topSection.addChild(new GendersTabs({}))

        if (this.props.showMobileSiteBannerLink === true) {
            const mobileSiteNotice = new MobileSiteNotice()
            // If shown, the mobile site banner should sit *above* the nav elements for visibility
            topSection.element.parentElement?.prepend(mobileSiteNotice.element)
            mobileSiteNotice.show()
        }
    }

    private makeRoomListResponsive(): void {
        if (!isiPhone() && !isiPod()) {
            // on iPhone body's minWidth is set as width making the screen 500px
            document.body.style.minWidth = `${500 - getScrollbarWidth()}px`
        }

        const content = document.querySelector(ROOMLIST_MAIN_CONTENT_ID_SELECTOR) as HTMLElement
        makeResponsive(content, 500, 600, [
            { name: "margin-left", min: 4, max: 15 },
            { name: "margin-right", min: 4, max: 15 },
        ])
    }

    /**
     * ROOM RELOAD TIMEOUT METHODS
     */

    private bindRoomRefreshTimers(): void {
        if (!isRoomRoomlistSpaActive()) {
            if (isPremium()) {
                RoomReload.scheduleImageRefresh(PREMIUM_REFRESH_SECONDS)
            } else {
                RoomReload.scheduleImageRefresh(spaPageContext.getState().refreshFrequency ?? this.props.refreshFrequency)
            }
        }
        // Because the SPA only loads info such as active split tests once at first pageload, we want a
        // mechanism to occasionally do a full page refresh. To avoid disrupting the user's browsing, we
        // check at each 15 minute interval whether the user has interacted with the document in the past
        // ONE minute, and if not we perform a full page reload instead of just a roomlist fetch.
        addEventListenerMultiPoly(
            ["click", "focusin", "keydown", "pointerdown", "pointermove", "scroll", "visibilitychange"],
            document,
            () => this.setUserActivityTimestamp(),
        )

        window.setInterval(() => this.inactiveRefreshCheck(), SPA_RELOAD_MILLISECONDS)
    }

    private inactiveRefreshCheck(): void {
        if (isRoomRoomlistSpaEligiblePage() && UrlState.current.state.room !== undefined) {
            // Prevent reload or refresh if on a broadcaster page and experiment is active.
            return
        }
        // Avoid refreshing in background if user hasn't actually interacted with document at all yet
        if (this.lastUserActivityTimestamp === 0) {
            return
        }
        // If the user has been inactive for long enough, do a full reload, otherwise reload room cards via API fetch
        if (Date.now() - this.lastUserActivityTimestamp >= INACTIVE_THRESHOLD_MILLISECONDS) {
            window.location.reload()
        } else {
            this.roomlistContainer?.handleRoomRefresh()
            this.secondaryContainer?.handleRoomRefresh()
            if (isFallbackRoomlistActive()) {
                this.fallbackContainer?.handleRoomRefresh()
            }
        }
    }

    private setUserActivityTimestamp(): void {
        this.lastUserActivityTimestamp = Date.now()
    }
}
