import { HTMLComponent } from "../../../../common/defui/htmlComponent"
import { addPageAction } from "../../../../common/newrelic"
import { dom } from "../../../../common/tsxrender/dom"
import { ReactWrapper } from "../../ReactWrapper"


export interface FilterLabelProps {
    title: string,
    onRemoval: () => void,
}

export class FilterLabel extends HTMLComponent<HTMLDivElement, FilterLabelProps> {
    protected props: FilterLabelProps

    protected createElement(props: FilterLabelProps): HTMLDivElement {
        return <div className="filterLabel" data-testid="filter-label-remove" data-ishashtag={props.title.startsWith("#")}>
            { props.title }
            <div onClick={() => { this.removeFilter(props) }} className="filterLabelRemove" >
                <ReactWrapper
                    component="RemoveFilterIcon"
                    componentProps={{}}
                />
            </div>
        </div>
    }

    protected initData(props: FilterLabelProps): void {
        super.initData(props)
        this.props = props
    }

    private removeFilter(props: FilterLabelProps): void {
        this.element.remove()
        addPageAction("HmpgFilterLabelClicked", { "title": this.props.title })
        props.onRemoval()
    }
}
