import {
    DEFAULT_MAX_AGE,
    DEFAULT_MIN_AGE,
    UrlState,
} from "@multimediallc/cb-roomlist-prefetch"
import { HTMLComponent } from "../../../../../common/defui/htmlComponent"
import { addPageAction } from "../../../../../common/newrelic"
import { i18n } from "../../../../../common/translation"
import { dom } from "../../../../../common/tsxrender/dom"

interface AgeSectionProps {onUpdate?: () => void}

interface AgeSectionState {
    minAge: number
    maxAge: number
    inputIsActive: boolean
}

export class AgeSection extends HTMLComponent<HTMLDivElement, AgeSectionProps, AgeSectionState> {
    private minAgeInput: HTMLInputElement
    private maxAgeInput: HTMLInputElement

    protected createElement(): HTMLDivElement {
        return <div className="filterSection" data-testid="filter-age-section">
            <div className="filterSectionHeader" data-testid="filter-age-header">{i18n.agesCAPS}</div>
            <a className="clear" href="#" data-testid="filter-age-clear-button"
                onClick={() => {
                    this.resetAgeFilters()
                    addPageAction("HmpgFilterAgeRangeReset")
                }}
            >({i18n.reset})</a>
            <div className="filterSectionOptions ageInputs">
                <input type="number"
                    className="min"
                    data-testid="filter-age-min-input"
                    ref={(el: HTMLInputElement) => { this.minAgeInput = el }}
                    value={DEFAULT_MIN_AGE}
                    onChange={() => this.handleMinAgeChange()}
                    onKeyDown={(evt: KeyboardEvent) => {
                        if ((evt.key ?? "") === "Enter") {
                            this.handleMinAgeChange(false)
                            this.handleSubmitClick()
                            this.minAgeInput.blur()
                        }
                        this.preventNonNumericChars(evt)
                    }}
                    onClick={(ev: MouseEvent) => this.handleInputClick(ev)}
                    onInput={() => this.setApplyButtonActive()}
                />
                <p>{i18n.ageRangeTo()}</p>
                <input type="number"
                    className="max"
                    data-testid="filter-age-max-input"
                    ref={(el: HTMLInputElement) => { this.maxAgeInput = el }}
                    onChange={() => this.handleMaxAgeChange()}
                    onKeyDown={(evt: KeyboardEvent) => {
                        if ((evt.key ?? "") === "Enter") {
                            this.handleMaxAgeChange(false)
                            this.handleSubmitClick()
                            this.maxAgeInput.blur()
                        }
                        this.preventNonNumericChars(evt)
                    }}
                    onClick={(ev: MouseEvent) => this.handleInputClick(ev)}
                    onInput={() => this.setApplyButtonActive()}
                />
                <a bind={{ className: () => `agesSubmit ${this.state.inputIsActive ? "active" : ""}` }}
                    href="#"
                    onClick={() => this.handleSubmitClick()}
                    data-testid="filter-age-submit"
                >{i18n.applyLower}</a>
            </div>
        </div>
    }

    protected initUI(props: AgeSectionProps): void {
        super.initUI(props)
        UrlState.current.listen(["ageMax", "ageMin"], () => {
            this.updateStateFromUrl()
        }, this.element)
        this.updateStateFromUrl()
    }

    public updateStateFromUrl(): void {
        this.setAges(
            UrlState.current.state.ageMin ?? DEFAULT_MIN_AGE,
            UrlState.current.state.ageMax === undefined ? DEFAULT_MAX_AGE : UrlState.current.state.ageMax - 1,
        )
    }

    // Ages are validated before passing into this method. URL param and number inputs handled in separate methods.
    // Here we ensure both state values and age inputs are updated and consistent with each other.
    private setAges(min: number, max: number): void {
        // Ensure input values are normalized even if state value is unchanged (mostly, clear the max input)
        this.minAgeInput.value = min.toString()
        // The capped max age should be set as an empty input to represent an open upper bound
        this.maxAgeInput.value = max === DEFAULT_MAX_AGE ? "" : max.toString()
        this.setState({ ...this.state, minAge: min, maxAge: max })
    }

    private getInputMaxAsNumber(): number {
        // The max value requires more care than the min value because "" is a valid input.
        // This method should be used to access the numerical value of  maxAgeInput.value.
        const parsedMax = this.maxAgeInput.valueAsNumber
        if (isNaN(parsedMax)) {
            return DEFAULT_MAX_AGE
        } else {
            return parsedMax
        }
    }

    private minIsInvalid(newMin: number): boolean {
        return isNaN(newMin) || newMin < DEFAULT_MIN_AGE || newMin >= DEFAULT_MAX_AGE
    }

    private maxIsInvalid(newMax: number): boolean {
        return newMax < DEFAULT_MIN_AGE || newMax > DEFAULT_MAX_AGE
    }

    private newInputsValid(newMin: number, newMax: number): boolean {
        return !this.minIsInvalid(newMin) && !this.maxIsInvalid(newMax)
    }

    private setApplyButtonActive(): void {
        // Input button should be active when input values are valid and differ from current filter values
        const newMin = this.minAgeInput.valueAsNumber
        const newMax = this.getInputMaxAsNumber()

        const urlStateMismatch = this.areInputsChanged(newMin, newMax)
        this.setState({ ...this.state, inputIsActive: this.newInputsValid(newMin, newMax) && urlStateMismatch })
    }

    private handleSubmitClick(): void {
        // Triggers page update with new min, max age filter settings if the inputs are valid and differ from current
        if (this.state.inputIsActive) {
            addPageAction("HmpgFilterAgeRangeSubmit", {
                "minAge": this.state.minAge,
                "maxAge": this.state.maxAge,
            })
            UrlState.current.setPartialState({ ageMin: this.state.minAge, ageMax: this.state.maxAge + 1 })
            this.setApplyButtonActive()
        }
    }

    public resetAgeFilters(): void {
        this.setAges(DEFAULT_MIN_AGE, DEFAULT_MAX_AGE)
        UrlState.current.clearStateKeys(["ageMin", "ageMax"])
        this.setApplyButtonActive()
    }

    private preventNonNumericChars(evt: KeyboardEvent): void {
        const keyVal = evt.key ?? ""
        // Length check avoids intercepting special keys such as arrows/tab/enter, etc. which have multi-character
        // representations, leaving only printable single characters. Then the isNaN check filters anything other
        // than digits (or space, which is already prevented from being entered in <input type="number">)
        if (keyVal.length === 1 && isNaN(Number(keyVal))) {
            evt.preventDefault()
        }
    }

    private handleMinAgeChange(firePageAction = true): void {
        let newMin, newMax, rawMin, rawMax
        newMin = rawMin = this.minAgeInput.valueAsNumber
        newMax = rawMax = this.state.maxAge
        // Invalid min value should get reset to the default
        // Upper bound comparison uses >= because you can't get results with min === 100
        if (this.minIsInvalid(newMin)) {
            newMin = DEFAULT_MIN_AGE
        }
        // if min > max assume the user intended the min value they just input, and reset max
        if (newMin > newMax) {
            newMax = DEFAULT_MAX_AGE
        }
        this.setAges(newMin, newMax)
        if (firePageAction) {
            // handleMinAgeChange will be called an extra time on keydown when Enter is pressed to prepare the input for submission in handleSubmitClick
            // In that case, the pageAction will only be fired onChange, like it does when the user clicks to make a change
            addPageAction("HmpgFilterAgeRangeUpdate", {
                "minAge": newMin,
                "maxAge": newMax,
                "rawMin": rawMin,
                "rawMax": rawMax,
                "changed": "min",
            })
        }
    }

    private handleMaxAgeChange(firePageAction = true): void {
        let newMin, newMax, rawMin, rawMax
        newMax = rawMax = this.getInputMaxAsNumber()
        newMin = rawMin = this.state.minAge
        // Invalid max value should get reset to the default
        // Reset newMax if it invalid.  The default max input of "" is converted to the default max in this.getInputMaxAsNumber()
        if (this.maxIsInvalid(newMax)) {
            newMax = DEFAULT_MAX_AGE
        }
        // if max < min assume the user intended the max value they just input, and reset min
        if (newMax < newMin) {
            newMin = DEFAULT_MIN_AGE
        }
        this.setAges(newMin, newMax)
        if (firePageAction) {
            // handleMaxAgeChange will be called an extra time on keydown when Enter is pressed to prepare the input for submission in handleSubmitClick
            // In that case, the pageAction will only be fired onChange, like it does when the user clicks to make a change
            addPageAction("HmpgFilterAgeRangeUpdate", {
                "minAge": newMin,
                "maxAge": newMax,
                "rawMin": rawMin,
                "rawMax": rawMax,
                "changed": "max",
            })
        }
    }

    private handleInputClick(evt: MouseEvent): void {
        (evt.currentTarget as HTMLInputElement).select()
    }

    private areInputsChanged(newMin: number, newMax: number) {
        const currentAgeMax = UrlState.current.state.ageMax === undefined ? DEFAULT_MAX_AGE : UrlState.current.state.ageMax - 1
        const currentAgeMin = UrlState.current.state.ageMin ?? DEFAULT_MIN_AGE
        return newMin !== currentAgeMin || newMax !== currentAgeMax
    }
}
