import { t } from "@lingui/macro"
import { PageType, ShowType, UrlState } from "@multimediallc/cb-roomlist-prefetch"
import { usernameTitleCase } from "@multimediallc/web-utils"
import { HTMLComponent } from "../../../common/defui/htmlComponent"
import { applyStyles, hoverEvent, ignoreMetaClick } from "../../../common/DOMutils"
import { Gender } from "../../../common/genders"
import { i18n } from "../../../common/translation"
import { dom } from "../../../common/tsxrender/dom"
import { CollapsibleComponent, ExpandableDropDownMenu } from "../../ui/expandableDropDownMenu"
import { SearchBlur } from "../../ui/searchBar/constants"
import { GenderDropdownToggle } from "../roomlist/subNavHeaderTabs"


export class GendersTabs extends HTMLComponent<HTMLUListElement> {
    private tabsDropdown?: ExpandableDropDownMenu
    private firstTab: HTMLAnchorElement

    protected createElement(): HTMLUListElement {
        return <ul className="sub-nav genderTabs">
            <li className="gender-tab">
                <a data-paction="TopTab" ref={(el) => this.firstTab = el as HTMLAnchorElement}
                    onClick={(e: MouseEvent) => {
                        this.onTabClick(e, Gender.All)
                    }}
                    bind={{
                        text: () => this.getFirstTabText(),
                        href: () => this.getLink(Gender.All),
                        className: () => `gender-tab${this.isFirstTabActive() ?
                            " active" : ""}${UrlState.current.state.pageType === PageType.ROOM ? " activeRoom" : ""}`,
                        ["data-TestId"]: () => `top-section-tab-${this.getFirstTabText().toLowerCase()}`,
                    }}> {t`FEATURED`}
                </a>
            </li>
            {[
                [t`WOMEN`, "women", Gender.Female],
                [t`MEN`, "men", Gender.Male],
                [t`COUPLES`, "couples", Gender.Couple],
                [t`TRANS`, "trans", Gender.Trans],
            ].map(([text, name, g]) =>
                <li className="gender-tab">
                    <a data-paction="TopTab" data-testid={`top-section-tab-${name}`}
                        onClick={(e: MouseEvent) => {
                            this.onTabClick(e, g as Gender)
                        }}
                        bind={{
                            href: () => this.getLink(g as Gender),
                            display: () => this.shouldShowGenders() ? "inline-block" : "none",
                            className: () => `gender-tab tabElement tabElementLink${this.isGenderTabActive(g as Gender) ? " active" : ""}`,
                        }}>{text}
                    </a>
                </li>,
            )}
        </ul>
    }

    private isGenderTabActive(gender: Gender): boolean {
        return UrlState.current.state.pageType !== PageType.ROOM && UrlState.current.state.genders?.[0] === gender
    }

    private isFirstTabActive(): boolean {
        return UrlState.current.state.genders === undefined || UrlState.current.state.pageType === PageType.ROOM
    }

    private onTabClick(e: MouseEvent, g: Gender): void {
        ignoreMetaClick(e, () => {
            if (UrlState.current.state.pageType === PageType.HOME) {
                UrlState.current.setPartialState({ genders: g !== Gender.All ? [g] : undefined })
            } else {
                if (!(g === Gender.All && UrlState.current.state.pageType === PageType.ROOM)) {
                    UrlState.current.navigateTo((e.currentTarget as HTMLAnchorElement).href)
                }
            }
        })
    }

    private shouldShowGenders(): boolean {
        return UrlState.current.state.pageType !== PageType.HOME || UrlState.current.state.showType !== ShowType.FOLLOW_OFFLINE
    }

    protected initUI(props: object): void {
        super.initUI(props)
        UrlState.current.listen(["pageType", "genders", "showType", "room"], () => {
            this.updateState()
        }, this.element)
        window.setTimeout(() => {
            this.makeGenderTabsResponsive()
            this.tabsDropdown = this.makeExpandableDropDown()
            this.addChild(this.tabsDropdown)
            this.updateState()
        })
    }

    updateState(): void {
        super.updateState()
    }

    private makeGenderTabsResponsive(): void {
        Array.from(this.element.children).filter(c => c instanceof HTMLElement && c.style.display !== "none").forEach(childEl => {
            if (childEl instanceof HTMLElement) {
                childEl.style.display = "inline-block"
                childEl.style.position = "relative"
                childEl.style.font = getComputedStyle(childEl).font
                const collapsibleComponent = new CollapsibleComponent<HTMLElement>(childEl)
                collapsibleComponent.onCollapseEvent.listen(collapsed => {
                    collapsibleComponent.element.style.margin = collapsed ? "5px 0" : ""
                    collapsibleComponent.element.style.display = collapsed ? "block" : "inline-block"
                })
                if (childEl.firstElementChild instanceof HTMLAnchorElement) {
                    // childEl.firstElementChild.style.cursor = getComputedStyle(childEl.firstElementChild).cursor
                    // childEl.firstElementChild.style.textDecoration = "none"
                    childEl.firstElementChild.classList.add("hover-event")
                    hoverEvent(childEl.firstElementChild, { ignoreTouch: true }).listen((isHover) => {
                        if (isHover) {
                            childEl.firstElementChild?.classList.add("hover")
                        } else {
                            childEl.firstElementChild?.classList.remove("hover")
                        }
                    })
                }
                this.attachChild(collapsibleComponent)
            }
        })
    }

    private makeExpandableDropDown(): ExpandableDropDownMenu {
        const dropDown = new ExpandableDropDownMenu()
        dropDown.setSpacerWidth(12)
        dropDown.element.classList.add("gender-tab")
        dropDown.dropDown.toggleEvent.listen((evt) => {
            GenderDropdownToggle.fire(evt.isShowing)
        })
        // UI changes that occur in response to SearchBlur can move elements such that
        // the click event's intended target can be gone before the event can be registered.
        // So, forward the toggleEvent info from the private child component to a public EventRouter visible to
        // RoomlistSearchInput, so that it responds immediately to the gender tab dropdown being toggled.
        // This way, the onInputBlur UI changes can trigger in response to the click, rather than either
        // immediately before it or on a timeout
        SearchBlur.listen(() => {
            // if search input is about to close, wait for it to collapse before updating
            dropDown.collapseIfNeeded()
            window.setTimeout(() => {
                dropDown.collapseIfNeeded()
            }, 250)
        })
        applyStyles(dropDown, {
            borderWidth: "1px",
            borderStyle: "solid",
            borderRadius: "4px 4px 0 0",
            height: "27px",
            lineHeight: "27px",
            marginRight: "2px",
            width: "41px",
        })
        applyStyles(dropDown.dropDown, {
            padding: "8px 20px 8px 0",
            width: "108px",
        })
        return dropDown
    }

    public get maxAvailableNonTabSpace(): number {
        const minimumTabsWidth = this.firstTab.offsetWidth + (this.tabsDropdown?.element.offsetWidth ?? 0)
        return this.element.offsetWidth - minimumTabsWidth
    }

    private getFirstTabText(): string {
        if (UrlState.current.state.pageType === PageType.ROOM && UrlState.current.state.room !== undefined) {
            return `${usernameTitleCase(UrlState.current.state.room)}'s Cam`
        } else if (UrlState.current.state.pageType === PageType.TAGS) {
            return i18n.allTagsCAPS
        } else if (UrlState.current.state.showType !== undefined &&
            [ShowType.FOLLOW_OFFLINE, ShowType.FOLLOW, ShowType.PRIVATE].includes(UrlState.current.state.showType)) {
            return i18n.allGendersCAPS
        } else {
            return i18n.featuredCAPS
        }
    }

    private getLink(gender: Gender): string {
        const state = UrlState.current.state
        if (gender === Gender.All && state.pageType === PageType.ROOM) {
            return `/${state.room}`
        }
        return UrlState.current.convertStateToUrl({
            pageType: state.pageType === PageType.ROOM ? PageType.HOME : state.pageType,
            showType: state.pageType === PageType.HOME ? state.showType : undefined,
            genders: [gender],
        })
    }
}
