import { t } from "@lingui/macro"
import { PRIVATE_PRICES_FILTER_KEY, ShowType, UrlState } from "@multimediallc/cb-roomlist-prefetch"
import { HTMLComponent } from "../../../../../common/defui/htmlComponent"
import { ignoreMetaClick } from "../../../../../common/DOMutils"
import { isLinguiBasicActive, isPrivateSpyBadgesActive } from "../../../../../common/featureFlagUtil"
import { addPageAction } from "../../../../../common/newrelic"
import { i18n } from "../../../../../common/translation"
import { dom } from "../../../../../common/tsxrender/dom"
import { PRIVATE_PRICE_OPTIONS, PRIVATE_PRICES_MAPPING } from "../constants"
import { FilterSectionBase } from "./filterSectionBase"
import type { FilterSectionProps } from "./filterSectionBase"
import type { PrivatePrices } from "@multimediallc/cb-roomlist-prefetch"

export class PrivatePriceSection extends HTMLComponent<HTMLDivElement> {
    protected createElement(props: object): HTMLDivElement {
        const mappings = PRIVATE_PRICES_MAPPING
        return <div className="filterSection" data-testid="filter-private-show-tokens-per-minute-section"
            bind={{ display: () => UrlState.current.state.showType === ShowType.PRIVATE ? "none" : "block" }}>
            <div className="filterSectionHeader"
                data-testid="filter-private-show-tokens-per-minute-header">
                {isLinguiBasicActive() ? t`PRIVATE SHOW TOKENS PER MINUTE` : i18n.privateTksPerMinCAPS}
            </div>
            <div className="filterSectionOptions">
                {[...mappings.keys()].map(k => 
                    <a
                        bind={{
                            className: () => `filterOption ${UrlState.current.state.privatePrices?.includes(k) ?? false ? "active" : ""}`,
                            href: () => UrlState.current.getURLForPartialState({ privatePrices: getToggledPrices(k) }),
                        }}
                        onClick={(e: MouseEvent) => ignoreMetaClick(e, () => {
                            const newPrices = getToggledPrices(k)
                            UrlState.current.setPartialState({ privatePrices: newPrices })
                            addPageAction("HmpgFilterOptionClicked", {
                                "category": "private_prices",
                                "value": k,
                                "active": newPrices.includes(k),
                            })
                        })}
                        data-testid="filter-private-show-tokens-per-minute-item"
                    >{mappings.get(k)}</a>,
                )}
            </div>
        </div>
    }
}

function getToggledPrices(price: PrivatePrices): PrivatePrices[] {
    const prices = UrlState.current.state.privatePrices ?? []
    const active = prices.includes(price)
    let newPrices: PrivatePrices[]
    if (isPrivateSpyBadgesActive()) {  // Feature flag enables multi-selecting price filters
        newPrices = active ? prices.filter((r: string) => r !== price) : [...prices, price]
    } else {
        newPrices = active ? [] : [price]
    }
    return newPrices.sort()
}

export class PrivatePriceFilterSection extends FilterSectionBase {

    protected createElement(props: FilterSectionProps): HTMLDivElement {
        this.filterKey = PRIVATE_PRICES_FILTER_KEY
        this.sectionOptions = PRIVATE_PRICE_OPTIONS
        this.sectionTitle = isLinguiBasicActive() ? t`PRIVATE SHOW TOKENS PER MINUTE` : i18n.privateTksPerMinCAPS
        this.isSingleSelect = !isPrivateSpyBadgesActive()
        return super.createElement(props)
    }

    protected optionIsActive(queryParamValue: string): boolean {
        const currentValue = UrlState.current.state["privatePrices"]
        if (currentValue === undefined) {
            return false
        }
        return currentValue.includes(queryParamValue as PrivatePrices)
    }
}
